<template>
  <div>
    <b-modal
      ref="modal-create-service"
      centered
      @hide="((e)=>{ if (modalInfo.loading) e.preventDefault()})"
    >
      <template #modal-title>
        <div>
          Creating Service
        </div>
      </template>

      
      <div @keydown.enter="createService()"> 
        <label class="font-weight-bold">Service name</label>
        <b-form-input  placeholder="My new Service..." :formatter="makeUpperCase" :state="inputIsValid" v-model="modalInfo.newServiceName" autofocus/>
      </div>
      
      <template #modal-footer>
        <div>
          <b-button :disabled="modalInfo.loading" variant="success" @click="createService()" >
            <span v-if="!modalInfo.loading">Submit</span>
            <b-spinner small v-else />
          </b-button>
        </div>
      </template>
    
    </b-modal>
  </div>
</template>

<script>
import { 
  BCol,
  BRow, 
  BButton, 
  BButtonGroup, 
  BSkeleton, 
  BModal, 
  BSpinner, 
  BFormInput, 
  BBadge, 
  BCollapse 
} from "bootstrap-vue";

import { makeToast } from "@/layouts/components/Popups";



  export default {
    components: {
      BCol,
      BRow, 
      BButton, 
      BButtonGroup, 
      BSkeleton, 
      BModal, 
      BSpinner, 
      BFormInput, 
      BBadge, 
      BCollapse     
    },
    data() {
      return {
        modalInfo:{
          modalOpen: false,
          loading: false,
          newServiceName: "",
          invalidFields: null
        },
        inputIsValid: null
      }
    },
    methods: {
      show(){
        this.$refs['modal-create-service'].show()
      },
      createService(){
        this.inputIsValid = null

        if(!this.modalInfo.newServiceName.length){
          this.inputIsValid = false        
          makeToast({
            title: "Can't submit yet",
            text: `Please fill in the remaining fields`,
            variant: "danger",
            icon: "XIcon",
          });
          return
        }

        this.modalInfo.loading = true
        this.$store
          .dispatch("addService", {
            transmissionID: this.$route.params.transmissionID,
            data:{
              identifier: this.modalInfo.newServiceName
            }
          })
          .then((resp) => {
            makeToast({
              title: 'Success!',
              text: `Service ${this.modalInfo.newServiceName} created successfully`,
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit('serviceCreated', resp.data)
            this.modalInfo.loading = false
            this.$nextTick(()=>{
              this.$refs['modal-create-service'].hide()
            })
          })
          .catch((err) => {
            makeToast({
              title: 'Error!',
              text: `Something went wrong white creating the Service`,
              variant: "danger",
              icon: "XIcon",
            });
            console.error(err)
          })
          .finally(()=>{
            this.modalInfo.loading = false
          })    
      },    
      makeUpperCase(text){
        return text.toUpperCase()
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>